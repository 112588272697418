body.login-page {
    padding: 5%;

    .logindiv {
        background-color: #00000000;
        box-shadow: 5px 10px 50px #1a2240;

        .two-columns {

            .logo-column {
                background-color: #0c55c1;

                .logo {
                    -webkit-filter: invert(100%);
                    filter: invert(100%);
                }
            }
        }
    }
}

@media (min-width:768px) {
    body.login-page {
        background: linear-gradient(90deg, #0c55c1 35.33%, #FFFFFF 35.33%);

        .two-columns {
            height: 75vh;
        }

        .w-md-50 {
            width: 50% !important;
        }
    }
}